/**
 * Localization strings used in the Schema Config and data model viewer
 *
 * @module
 */

import { createDictionary } from './utils';

// Refer to "Guidelines for Programmers" in ./README.md before editing this file

export const schemaText = createDictionary({
  table: {
    'en-us': 'Table',
    'ru-ru': 'Стол',
    'es-es': 'Mesa',
    'fr-fr': 'Tableau',
    'uk-ua': 'Таблиця',
    'de-ch': 'Tabelle',
  },
  tables: {
    'en-us': 'Tables',
    'ru-ru': 'Таблицы',
    'es-es': 'Mesas',
    'fr-fr': 'les tables',
    'uk-ua': 'Таблиці',
    'de-ch': 'Tabellen',
  },
  tableName: {
    'en-us': 'Table Name',
    'ru-ru': 'Имя таблицы',
    'fr-fr': 'Nom de la table',
    'uk-ua': 'Назва таблиці',
    'de-ch': 'Tabellennamen',
    'es-es': 'Nombre de la tabla',
  },
  withoutTable: {
    'en-us': 'Without Table',
    'de-ch': 'Ohne Tisch',
    'es-es': 'Sin mesa',
    'fr-fr': 'Sans tableau',
    'ru-ru': 'Без стола',
    'uk-ua': 'Без таблиці',
  },
  schemaConfig: {
    'en-us': 'Schema Config',
    'ru-ru': 'Конфигурация схемы',
    'es-es': 'Configuración de esquema',
    'fr-fr': 'Configuration du schéma',
    'uk-ua': 'Конфігурація схеми',
    'de-ch': 'Schema konfigurieren',
  },
  unsavedSchemaUnloadProtect: {
    'en-us': 'Schema changes have not been saved',
    'ru-ru': 'Изменения схемы не сохранены.',
    'es-es': 'Los cambios de esquema no se han guardado.',
    'fr-fr': "Les modifications du schéma n'ont pas été enregistrées",
    'uk-ua': 'Зміни схеми не збережено',
    'de-ch': 'Schema Änderungen wurden nicht gespeichert',
  },
  changeBaseTable: {
    'en-us': 'Change Base Table',
    'ru-ru': 'Изменить базовую таблицу',
    'es-es': 'Cambiar tabla base',
    'fr-fr': 'Modifier la table de base',
    'uk-ua': 'Змінити базову таблицю',
    'de-ch': 'Basis-Tabelle ändern',
  },
  field: {
    'en-us': 'Field',
    'ru-ru': 'Поле',
    'es-es': 'Campo',
    'fr-fr': 'Champ',
    'uk-ua': 'Поле',
    'de-ch': 'Feld',
  },
  fields: {
    'en-us': 'Fields',
    'ru-ru': 'Поля',
    'es-es': 'Campos',
    'fr-fr': 'Des champs',
    'uk-ua': 'поля',
    'de-ch': 'Felder',
  },
  relationships: {
    'en-us': 'Relationships',
    'ru-ru': 'Отношения',
    'es-es': 'Relaciones',
    'fr-fr': 'Des relations',
    'uk-ua': 'стосунки',
    'de-ch': 'Beziehungen',
  },
  database: {
    'en-us': 'Database',
    'de-ch': 'Datenbank',
    'fr-fr': 'Base de données',
    'ru-ru': 'База данных',
    'uk-ua': 'База даних',
    'es-es': 'Base de datos',
  },
  setScope: {
    'en-us': 'Set Scope',
    'de-ch': 'Umfang festlegen',
    'es-es': 'Establecer alcance',
    'fr-fr': 'Définir la portée',
    'ru-ru': 'Установить область',
    'uk-ua': 'Встановити область',
  },
  caption: {
    'en-us': 'Caption',
    'ru-ru': 'Подпись',
    'es-es': 'Subtítulo',
    'fr-fr': 'Légende',
    'uk-ua': 'Підпис',
    'de-ch': 'Beschriftung',
  },
  description: {
    'en-us': 'Description',
    'ru-ru': 'Описание',
    'es-es': 'Descripción',
    'fr-fr': 'Description',
    'uk-ua': 'опис',
    'de-ch': 'Beschreibung',
  },
  hideTable: {
    'en-us': 'Hide Table',
    'ru-ru': 'Скрыть таблицу',
    'es-es': 'Ocultar tabla',
    'fr-fr': 'Masquer le tableau',
    'uk-ua': 'Сховати таблицю',
    'de-ch': 'Tabelle verbergen',
  },
  hideField: {
    'en-us': 'Hide Field',
    'ru-ru': 'Скрыть поле',
    'es-es': 'Ocultar campo',
    'fr-fr': 'Masquer le champ',
    'uk-ua': 'Приховати поле',
    'de-ch': 'Feld verbergen',
  },
  tableFormat: {
    'en-us': 'Table Format',
    'ru-ru': 'Формат таблицы',
    'es-es': 'Formato de tabla',
    'fr-fr': 'Format du tableau',
    'uk-ua': 'Формат таблиці',
    'de-ch': 'Tabellenformat',
  },
  tableAggregation: {
    'en-us': 'Table Aggregation',
    'ru-ru': 'Агрегация таблиц',
    'es-es': 'Agregación de tablas',
    'fr-fr': 'Agrégation de tables',
    'uk-ua': 'Агрегація таблиць',
    'de-ch': 'Tabellenaggregation',
  },
  oneToOne: {
    'en-us': 'One-to-one',
    'ru-ru': 'Один к одному',
    'es-es': 'Cara a cara',
    'fr-fr': 'Un par un',
    'uk-ua': 'Один до одного',
    'de-ch': 'Eins zu eins',
  },
  oneToMany: {
    'en-us': 'One-to-many',
    'ru-ru': 'Один ко многим',
    'es-es': 'Uno a muchos',
    'fr-fr': 'Un à plusieurs',
    'uk-ua': 'Один до багатьох',
    'de-ch': 'Eins zu vielen',
  },
  manyToOne: {
    'en-us': 'Many-to-one',
    'ru-ru': 'Многие к одному',
    'es-es': 'muchos a uno',
    'fr-fr': 'Plusieurs-à-un',
    'uk-ua': 'Багато-до-одного',
    'de-ch': 'Viele zu eins',
  },
  manyToMany: {
    'en-us': 'many-to-many',
    'ru-ru': 'многие-ко-многим',
    'es-es': 'muchos a muchos',
    'fr-fr': 'plusieurs à plusieurs',
    'uk-ua': 'багато-до-багатьох',
    'de-ch': 'Viele zu viele',
  },
  fieldLength: {
    'en-us': 'Length',
    'es-es': 'Longitud',
    'fr-fr': 'Sans tableau',
    'uk-ua': 'Без таблиці',
    'de-ch': 'Länge',
    'ru-ru': 'Без стола',
  },
  readOnly: {
    'en-us': 'Read-only',
    'ru-ru': 'Только чтение',
    'es-es': 'Solo lectura',
    'fr-fr': 'Lecture seulement',
    'uk-ua': 'Лише для читання',
    'de-ch': 'Nur-Lesen',
  },
  fieldFormat: {
    'en-us': 'Field Format',
    'ru-ru': 'Формат поля',
    'es-es': 'Formato de campo',
    'fr-fr': 'Format du champ',
    'uk-ua': 'Формат поля',
    'de-ch': 'Feldformat',
  },
  formatted: {
    'en-us': 'Formatted',
    'ru-ru': 'Отформатированный',
    'es-es': 'formateado',
    'fr-fr': 'Formaté',
    'uk-ua': 'Відформатований',
    'de-ch': 'Formatiert',
  },
  webLink: {
    'en-us': 'Web Link',
    'ru-ru': 'Интернет-ссылка',
    'es-es': 'Enlace web',
    'fr-fr': 'Lien Web',
    'uk-ua': 'Веб посилання',
    'de-ch': 'Web-Link',
  },
  userDefined: {
    'en-us': 'User Defined',
    'es-es': 'Usuario definido',
    'fr-fr': "Défini par l'utilisateur",
    'uk-ua': 'Визначений користувачем',
    'de-ch': 'Benutzerdefiniert',
    'ru-ru': 'Определяемые пользователем',
  },
  addLanguage: {
    'en-us': 'Add Language',
    'fr-fr': 'Champs uniques',
    'uk-ua': 'Унікальні поля',
    'de-ch': 'Sprache hinzuzufügen',
    'es-es': 'Campos únicos',
    'ru-ru': 'Уникальные поля',
  },
  databaseColumn: {
    'en-us': 'Database Column',
    'ru-ru': 'Столбец базы данных',
    'es-es': 'Columna de base de datos',
    'fr-fr': 'Colonne de base de données',
    'uk-ua': 'Стовпець бази даних',
    'de-ch': 'Datenbank-Spalte',
  },
  relatedTable: {
    'en-us': 'Related Model',
    'ru-ru': 'Сопутствующая модель',
    'es-es': 'Modelo relacionado',
    'fr-fr': 'Modèle associé',
    'uk-ua': "Пов'язана модель",
    'de-ch': 'Zugehöriges Modell',
  },
  otherSideName: {
    'en-us': 'Other side name',
    'ru-ru': 'Другое боковое название',
    'fr-fr': 'Autre nom de côté',
    'uk-ua': 'Інша сторона імені',
    'de-ch': 'Name der anderen Seite',
    'es-es': 'Longitud',
  },
  dependent: {
    'en-us': 'Dependent',
    'ru-ru': 'Зависимый',
    'es-es': 'Dependiente',
    'fr-fr': 'Dépendant',
    'uk-ua': 'Утриманець',
    'de-ch': 'Abhängig',
  },
  independent: {
    'en-us': 'Independent',
    'es-es': 'Independiente',
    'fr-fr': 'Indépendant',
    'ru-ru': 'Независимый',
    'uk-ua': 'Незалежний',
    'de-ch': 'Unabhängig',
  },
  downloadAsJson: {
    'en-us': 'Download as JSON',
    'ru-ru': 'Скачать в формате JSON',
    'es-es': 'Descargar como JSON',
    'fr-fr': 'Télécharger en JSON',
    'uk-ua': 'Завантажити як JSON',
    'de-ch': 'Als JSON herunterladen',
  },
  downloadAsXml: {
    'en-us': 'Download as XML',
    'ru-ru': 'Скачать как XML',
    'es-es': 'Descargar como XML',
    'fr-fr': 'Télécharger au format XML',
    'uk-ua': 'Завантажити як XML',
    'de-ch': 'Als XML herunterladen',
  },
  downloadAsTsv: {
    'en-us': 'Download as TSV',
    'ru-ru': 'Скачать как TSV',
    'es-es': 'Descargar como TSV',
    'fr-fr': 'Télécharger au format TSV',
    'uk-ua': 'Завантажити як TSV',
    'de-ch': 'Als TSV herunterladen',
  },
  tableId: {
    'en-us': 'Table ID',
    'ru-ru': 'Идентификатор таблицы',
    'es-es': 'ID de tabla',
    'fr-fr': 'ID de table',
    'uk-ua': 'Ідентифікатор таблиці',
    'de-ch': 'Tabellen-ID',
  },
  fieldCount: {
    'en-us': 'Field count',
    'ru-ru': 'Количество полей',
    'es-es': 'Recuento de campos',
    'fr-fr': 'Nombre de champs',
    'uk-ua': 'Підрахунок полів',
    'de-ch': 'Anzahl Felder',
  },
  relationshipCount: {
    'en-us': 'Relationship count',
    'ru-ru': 'Количество отношений',
    'es-es': 'recuento de relaciones',
    'fr-fr': 'Nombre de relations',
    'uk-ua': 'Кількість стосунків',
    'de-ch': 'Anzahl Beziehungen',
  },
  databaseSchema: {
    'en-us': 'Database Schema',
    'ru-ru': 'Схема базы данных',
    'es-es': 'Esquema de base de datos',
    'fr-fr': 'Schéma de base de données',
    'uk-ua': 'Схема бази даних',
    'de-ch': 'Datenbankschema',
  },
  selectedTables: {
    'en-us': 'Selected Tables',
    'ru-ru': 'Выбранные таблицы',
    'es-es': 'Tablas seleccionadas',
    'fr-fr': 'Tableaux sélectionnés',
    'uk-ua': 'Вибрані таблиці',
    'de-ch': 'Ausgewählte Tabellen',
  },
  possibleTables: {
    'en-us': 'Possible Tables',
    'ru-ru': 'Возможные таблицы',
    'es-es': 'Posibles tablas',
    'fr-fr': 'Tableaux possibles',
    'uk-ua': 'Можливі таблиці',
    'de-ch': 'Mögliche Tabellen',
  },
  goToTop: {
    'en-us': 'Go to top',
    'es-es': 'Ve arriba',
    'fr-fr': 'Aller en haut',
    'uk-ua': 'Перейти вгору',
    'de-ch': 'Nach oben',
    'ru-ru': 'Перейти наверх',
  },
  idField: {
    'en-us': 'ID Field',
    'es-es': 'Campo de identificación',
    'fr-fr': "Champ d'identification",
    'ru-ru': 'Поле идентификатора',
    'uk-ua': 'Поле ID',
    'de-ch': 'Feld-ID',
  },
  tableUniquenessRules: {
    'en-us': '{tableName:string} Uniqueness Rules',
    'de-ch': '{tableName:string} Eindeutigkeitsregeln',
    'es-es': '{tableName:string} Reglas de unicidad',
    'fr-fr': "{tableName:string} Règles d'unicité",
    'ru-ru': '{tableName:string} Правила уникальности',
    'uk-ua': '{tableName:string} Правила унікальності',
  },
  uniquenessRules: {
    'en-us': 'Uniqueness Rules',
    'de-ch': 'Eindeutigkeitsregeln',
    'es-es': 'Reglas de unicidad',
    'fr-fr': "Règles d'unicité",
    'ru-ru': 'Правила уникальности',
    'uk-ua': 'Правила унікальності',
  },
  uniqueFields: {
    'en-us': 'Unique Fields',
    'de-ch': 'Nach ausgeblendeten Feldern sortieren',
    'es-es': 'Campos únicos',
    'fr-fr': 'Champs uniques',
    'ru-ru': 'Уникальные поля',
    'uk-ua': 'Унікальні поля',
  },
  addUniquenessRule: {
    'en-us': 'Add Uniqueness Rule',
    'de-ch': 'Eindeutigkeitsregel hinzufügen',
    'es-es': 'Agregar regla de unicidad',
    'fr-fr': "Ajouter une règle d'unicité",
    'ru-ru': 'Добавить правило уникальности',
    'uk-ua': 'Додайте правило унікальності',
  },
  configureUniquenessRule: {
    'en-us': 'Configure Uniqueness Rule',
    'de-ch': 'Eindeutigkeitsregel konfigurieren',
    'es-es': 'Configurar la regla de unicidad',
    'fr-fr': "Configurer la règle d'unicité",
    'ru-ru': 'Настройка правила уникальности',
    'uk-ua': 'Налаштувати правило унікальності',
  },
  scope: {
    'en-us': 'Scope',
    'es-es': 'Alcance',
    'fr-fr': 'Portée',
    'ru-ru': 'Объем',
    'uk-ua': 'Область застосування',
    'de-ch': 'Anwendungsbereich',
  },
  uniquenessDuplicatesFound: {
    'en-us': 'Duplicates found in database',
    'de-ch': 'In der Datenbank gefundene Duplikate',
    'es-es': 'muchos a uno',
    'fr-fr': 'Doublons trouvés dans la base de données',
    'ru-ru': 'В базе данных найдены дубликаты',
    'uk-ua': 'У базі даних знайдено дублікати',
  },
  exportDuplicates: {
    'en-us': 'Export Duplicates',
    'de-ch': 'Duplikate exportieren',
    'es-es': 'Exportar duplicados',
    'fr-fr': 'Exporter les doublons',
    'ru-ru': 'Экспортировать дубликаты',
    'uk-ua': 'Експортувати дублікати',
  },
  numberOfDuplicates: {
    'en-us': 'Number of Duplicates',
    'de-ch': 'Anzahl Duplikate',
    'es-es': 'Número de duplicados',
    'fr-fr': 'Nombre de champs',
    'ru-ru': 'Количество полей',
    'uk-ua': 'Підрахунок полів',
  },
  schemaViewTitle: {
    'en-us': 'Schema Config: {tableName:string}',
    'es-es': 'Configuración de esquema: {tableName:string}',
    'fr-fr': 'Configuration du schéma : {tableName:string}',
    'ru-ru': 'Конфигурация схемы: {tableName:string}',
    'uk-ua': 'Конфігурація схеми: {tableName:string}',
    'de-ch': 'Schema-Konfiguration: {tableName:string}',
  },
  schemaExportFileName: {
    'en-us': 'Specify 7 Data Model',
    'ru-ru': 'Укажите 7 моделей данных',
    'es-es': 'Especifique 7 modelos de datos',
    'fr-fr': 'Spécifier 7 modèles de données',
    'uk-ua': 'Укажіть модель даних 7',
    'de-ch': 'Specify 7 Datenmodell',
  },
  sortByHiddenFields: {
    'en-us': 'Sort by hidden fields',
    'de-ch': 'Nach ausgeblendeten Feldern sortieren',
    'es-es': 'Ordenar por campos ocultos',
    'fr-fr': 'Trier par champs masqués',
    'ru-ru': 'Сортировка по скрытым полям',
    'uk-ua': 'Сортувати за прихованими полями',
  },
  hidden: {
    'en-us': 'hidden',
    'de-ch': 'versteckt',
    'es-es': 'oculto',
    'fr-fr': 'caché',
    'ru-ru': 'скрытый',
    'uk-ua': 'прихований',
  },
  customFieldFormat: {
    'en-us': 'Custom Field Format',
    'de-ch': 'Benutzerdefiniertes Feldformat',
    'es-es': 'Formato de campo personalizado',
    'fr-fr': 'Format de champ personnalisé',
    'ru-ru': 'Пользовательский формат поля',
    'uk-ua': 'Спеціальний формат поля',
  },
} as const);
